body {
    min-width: 768px;
    max-width: 3960px;
}

/* header */
.header {
    background: #fff;
    border-bottom: 1px solid #e1e4e8;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 80px;
}

.header .header_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.header .header_container .header_logo img {
    margin: 20px 35px;
    cursor: pointer;
}

.header .header_user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #4a535f;
    margin-right: 40px;
}

.header .header_user li {
    margin-left: 35px;
}
.header .header_user .user{
    margin-left: 5px;
}
.header .header_user .user span {
    font-weight: 500;
}

.header .header_user .link {
    line-height: 8px;
    border: 1px solid #4a535f;
    color: #fff;
    border-radius: 100px;
    padding: 5px 15px;
    transition: all 0.5s;
    cursor: pointer;
}

.header .header_user .link:hover {
    background: #4a535f;
}

.header .header_user .link button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.header .header_user .link:hover a,
.header .header_user .link a:hover {
    color: #fff;
}

/* container */
.container {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

/* section */
.divide_section {
    display: flex;
    width: 100%;
}

.divide_section div.table {
    width: 70%;
    z-index: 15;
    padding-top: 50px;
    position: relative;
}

.divide_section div.table .search_box {
    position: absolute;
    top: 0;
    left: 0;
}

.divide_section div.table .search_box .btn_gray_line {
    width: 70px;
}

.divide_section div.chart {
    width: 40%;
    padding-top: 130px;
    padding-left: 50px;
    position: relative;
    z-index: 10;
}

table.color_table {
    width: 450px;
    border: 1px solid #eee;
    text-align: center;
    font-size: 11px;
    color: #4a535f;
    margin-bottom: 15px;
}

table.color_table thead{
  border-bottom: 1px solid #eee;

}

table.color_table th {
    font-weight: 500;
    padding: 10px 10px;
}
table.color_table td{
    border : 1px solid #eee;;
}
/*div.chart .month{*/
/*    font-size: 22px;*/
/*    position: absolute;*/
/*    left: 51%;*/
/*    top : 58%;*/

/*}*/

/* side_menu */
.side_menu {
    width: 250px;
    min-width: 250px;
    min-height: 100vh;
    background: #f5f7f9;
    border-right: 1px solid #e1e4e8;
    position: relative;
}

.side_menu .dep_1 {
    border-bottom: 1px solid #e1e4e8;
    padding: 18px;
    cursor: pointer;
}

.side_menu .dep_1:last-child {
    border-bottom: none;
    margin-bottom: 20px;
}

.side_menu .dep_1 p {
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    font-weight: 500;
    color: #4d5867;
    text-decoration: none;
    width: calc(100% - 40px);
}

.side_menu .dep_1 .icon {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-right: 16px;
}

.side_menu .dep_1 .menu_1 {
    background: url(../images/img_sprite.png) left top no-repeat;
}

.side_menu .dep_1 .menu_2 {
    background: url(../images/img_sprite.png) -43px top no-repeat;
}

.side_menu .dep_1 .menu_3 {
    background: url(../images/img_sprite.png) -86px top no-repeat;
}

.side_menu .dep_1 .menu_4 {
    background: url(../images/img_sprite.png) -128px top no-repeat;
}

.side_menu .dep_1 .menu_5 {
    background: url(../images/img_sprite.png) -171px top no-repeat;
}

.side_menu .dep_1 .menu_6 {
    background: url(../images/img_sprite.png) -211px top no-repeat;
}

.side_menu .dep_1 .menu_7 {
    background: url(../images/img_sprite.png) -251px top no-repeat;
}

.side_menu .dep_1 .menu_8 {
    background: url(../images/img_sprite.png) -291px top no-repeat;
}

.side_menu .dep_1 .menu_9 {
    background: url(../images/img_sprite.png) -334px top no-repeat;
}

.side_menu .dep_1 > ul {
    margin-top: 20px;
    margin-left: 38px;
}

.side_menu .dep_2 {
    font-size: 15px;
    font-weight: 400;
    color: #767575;
    text-decoration: none;
    line-height: 18px;
    width: 100%;
}

.side_menu .dep_3 {
    font-size: 15px;
    font-weight: 400;
    color: #767575;
    text-decoration: none;
    line-height: 18px;
    width: 100%;
    margin-bottom: 15px;
    margin-left: 18px;
}

.side_menu .divide {
    padding-bottom: 10px;
    border-bottom: 1px solid #dadada;
    width: 140px;
}

.dep_title {
    font-size: 16px !important;
    margin-bottom: 15px;
    margin-top: 5px;
}

.side_menu .dep_2 .active {
    font-weight: 600;
}

.side_menu .dep_2 {
    margin-bottom: 15px;
}

.side_menu .dep_2:last-child,
.side_menu .dep_3:last-child {
    margin-bottom: 0;
}

.side_menu .dep_1:last-child {
    padding-bottom: 60px;
}

small.copyright {
    display: block;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #d9d9d9;
    position: absolute;
    left: 20px;
    bottom: 15px;
}

small.side_menu::after {
    content: "";
    clear: both;
}

/* content_wrap */
.content_wrap {
    width: 100%;
    padding: 50px;
}

/* page_info */
.page_info_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 30px;
}

.page_info_wrap .page_title {
    font-size: 32px;
    font-weight: 700;
    color: #4a535f;
}

.page_info_wrap .page_desc {
    padding-left: 10px;
    font-size: 15px;
    font-weight: 400;
    color: rgba(74, 83, 95, 0.7);
}

.page_info_wrap .breadcrumbs,
.page_info_wrap .breadcrumbs a {
    font-size: 14px;
    font-weight: 100;
    color: #c1c1c1;
    padding-top: 10px;
    text-decoration: none;
}

.page_info_wrap .breadcrumbs li {
    display: inline-block;
}

.page_info_wrap .breadcrumbs li::after {
    content: "";
    display: inline-block;
    width: 4px;
    height: 10px;
    background: url(../images/img_sprite.png) left -41px no-repeat;
    margin: 0 12px;
}

.page_info_wrap .breadcrumbs li:last-child {
    font-weight: 400;
}

.page_info_wrap .breadcrumbs li:last-child::after {
    display: none;
}

/* table_basic */
.table_basic {
    width: 100%;
}

.table_basic th,
.table_basic td {
    border: 1px solid #e9ecf0;
    border-right: transparent;
    text-align: center;
    background: #f8f8f9;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 25px;
    vertical-align: middle;
    line-height: 22px;
    word-break: keep-all;
}

.table_basic th p.desc {
    font-size: 12px;
    font-weight: 400;
    color: #808080;
}

.table_basic th:first-child,
.table_basic td:first-child {
    border-left: transparent;
}

.table_basic td {
    font-size: 15px;
    font-weight: 400;
    background: #fff;
    color: #4a535f;
}

.table_basic tr:hover td {
    background: #e8ebed; /* Change the background color of all <td> elements within the hovered <tr> */
}

.table_basic input,
.table_basic select #addTableBody input,
#addTableBody select {
    border: 1px solid #e9ecf0;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #666670;
    padding: 12px 15px;

}


.table_basic input:focus,
.table_basic select:focus,
#addTableBody input:focus,
#addTableBody select:focus {
    outline: 1px solid #002060;
}

.table_basic .arrow_sort {
    width: 10px;
    height: 17px;
    padding-right: 10px;
    vertical-align: middle;
    margin-left: 5px;
}

.arrow_asc {
    background: url(../images/img_sprite.png) -161px -37px no-repeat;
}

.arrow_desc {
    background: url(../images/img_sprite.png) -171px -37px no-repeat;
}

.wrapper {
    width: 100%;
    padding: 50px;
}

.wrapper_sub_title {
    font-size: 22px;
    font-weight: 700;
    color: #4a535f;
    margin: 30px 0 20px;
    cursor: pointer;
}

.wrapper_sub_title > .icon_right_arrow {
    margin-left: 5px;
    padding-right: 10px;
    background: url(../images/img_sprite.png) -94px -28px no-repeat;
}

.wrapper > hr {
    border: transparent;
    border-top: 1px solid #d9d9d9;
    padding-bottom: 20px;
}

.wrapper .desc {
    font-size: 14px;
    color: #666670;
}

.form_wrap li {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    /*align-items: center;*/
    /*width: 830px;*/
    margin-bottom: 25px;
}

.join_us li {
    /*align-items: center;*/
    width: 830px;
}

.join_us li.sub_title {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 15px;
}

.join_us li.sub_title label {
    width: 118px !important;
    font-size: 22px !important;
    border-radius: 8px;
}

.form_wrap div.li_box {
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.form_wrap div.li_box li {
    margin-bottom: 0;
    width: 420px;
    display: flex;
}

.form_wrap div.first {
    padding-top: 25px;
}


.form_wrap div.li_box input {
    width: 155px;
}


.form_wrap li label.name {
    width: 250px;
    font-size: 18px;
    font-weight: 500;
    color: #4a535f;
    margin-top: 12px;
}

.form_wrap li label.desc {
    width: 600px;
    font-size: 18px;
    font-weight: 500;
    color: #4a535f;
    margin-top: 12px;
}

.form_wrap li label.desc span {
    font-size: 14px;
    font-weight: 400;
    color: #4a535f;
    padding-left: 20px;
}

sup.required {
    color: #ff5c5c;
}

.form_wrap li input,
.form_wrap li select,
.search_box input,
.search_box select {
    width: 580px;
    height: 46px;
    border: 1px solid #e1e4e8;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #666670;
    padding: 13px;
}

.form_wrap li input:focus,
.form_wrap li select:focus,
select:focus,
.search_box input:focus {
    outline: 1px solid #002060;
}

select:disabled,
select option:disabled {
    border: 1px solid #e1e4e8 !important;
    background: #fafafa;
    /* cursor:not-allowed;
      */
}

.sub_input {
    margin: 15px 0 0 250px;
}

.form_wrap ul.alert_info {
    margin-top: 18px;
    border-top: 1px solid #d9d9d9;
    padding-top: 24px;
}

.form_wrap ul.alert_info li {
    margin-bottom: 13px;
}


.form_wrap ul.alert_info p.sub_title {
    font-size: 16px;
    display: inline-block;
    background-color: #a0a5a9;
    color: white;
    padding: 5px;
    border-radius: 5px;
}


/* 검색바 */
.search_box {
    margin-bottom: 50px;
    display: flex;
    z-index: 100;
}

.search_box input {
    width: 260px !important;
}

.search_box select {
    width: 260px !important;
}

.search_box label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    color: #767575;
}

.search_box .input_date {
    width: 150px !important;
}

input[type="date"]:valid {
    color: #c1c1c1;
}

span[name="endDate"] {
    display: none;
}

.custom-input {
    color: #c1c1c1;
    width: 250px;
    height: 46px;
    border: 1px solid #e1e4e8;
    background-color: #fff;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    text-align: left;
    padding-left: 13px;
}

/* .search_box .btn_gray_line{
    margin-top: 24px;
} */
.search_box .search_wrap {
    position: relative;
    margin-right: 10px;
}

.search_box .search_wrap::after {
    content: "";
    clear: both;
}

.search_box .btn_search {
    position: absolute;
    top: 13px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: url(../images/img_sprite.png) -20px -38px no-repeat;
    text-indent: -99999px;
    border: none;
}

.search_box .btn_init {
    position: absolute;
    top: 13px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    text-indent: -99999px;
    border: none;
}

/* 페이징 */
.btn_wrap {
    font-size: 16px;
    color: #767575;
    align-items: center;
}

.btn_wrap .paging {
    display: flex;
}

.btn_wrap .paging .btn_pg {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    cursor: pointer;
    margin: 0 5px;
    font-size: 15px;
    font-weight: 400;
    color: #1c304a;
    text-align: center;
    line-height: 30px;
}

.btn_wrap .paging .clicked {
    background: #eee;
}

.btn_wrap .paging .bg_none {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    cursor: pointer;
    margin: 0 5px;
    vertical-align: middle;
}

.btn_wrap .paging .bg_none i {
    display: block;
    width: 13px;
    height: 12px;
    margin: 10px auto;
    padding: 6px;
}

.btn_wrap .paging .bg_none i.first {
    background: url(../images/img_sprite.png) -51px -40px no-repeat;
}

.btn_wrap .paging .bg_none i.pre {
    background: url(../images/img_sprite.png) -77px -40px no-repeat;
}

.btn_wrap .paging .bg_none i.next {
    background: url(../images/img_sprite.png) -92px -40px no-repeat;
}

.btn_wrap .paging .bg_none i.end {
    background: url(../images/img_sprite.png) -117px -40px no-repeat;
}

/* 모달 */
.modal {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: left;
    background: rgba(0, 0, 0, 0.4);
    transition: opacity 0.25s ease;
    z-index: 51;
}

.modal .wrapper {
    padding: 0;
}

.modal_bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
}

.modal_state {
    display: none;
}

.modal_state:checked + .modal {
    opacity: 1;
    visibility: visible;
}

.modal_state:checked + .modal .modal_inner {
    top: 0;
}

.modal_inner {
    transition: top 0.25s ease;
    position: absolute;
    top: -20%;
    right: 0;
    bottom: 0;
    left: 0;
    width: fit-content;
    max-width: 920px;
    height: fit-content;
    max-height: calc(100% - 200px);
    margin: auto;
    overflow: auto;
    background: #fff;
    border-radius: 5px;
    padding: 30px;
}

.modal_inner_sm {
    width: 610px;
    height: fit-content;
}

.modal_close {
    position: absolute;
    right: 40px;
    top: 30px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.modal_close:after,
.modal_close:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 1.5em;
    background: #ccc;
    display: block;
    transform: rotate(45deg);
    left: 50%;
    margin: -3px 0 0 -1px;
    top: 0;
}

.modal_close:hover:after,
.modal_close:hover:before {
    background: #9fa19f;
}

.modal_close:before {
    transform: rotate(-45deg);
}

.modal_inner .modal_title {
    font-size: 20px;
    font-weight: 500;
    color: #1c304a;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
}

/* 체크박스 */
.chk_box {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 24px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.chk_box input[type="checkbox"] {
    display: none;
}

.chk_box .on {
    width: 26px;
    height: 26px;
    border-radius: 24px;
    border: 1px solid #ddd;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
}

.chk_box input[type="checkbox"]:checked + .on {
    border: 1px solid #002060;
    background: #002060;
}

.chk_box .on:after {
    content: "";
    position: absolute;
    display: none;
}

.chk_box input[type="checkbox"]:checked + .on:after {
    display: block;
}

.chk_box .on:after {
    width: 6px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 8px;
    top: 4px;
}

ul.disagreement {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    padding-top: 10px;
}

ul.disagreement p {
    font-weight: 500;
    color: #000;
    margin-right: 10px;
}

ul.disagreement li {
    color: #4a535f;
    margin-right: 10px;
}

ul.agree_ul {
    width: 850px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    border-top: 1px solid #d9d9d9;
}

ul.agree_ul li {
    display: flex;
    justify-content: left;
    margin-bottom: 0;
    font-size: 15px;
    padding: 10px 5px;
    width: 25%;

}

ul.agree_ul li label {
    max-width: 165px;
}

ul.agree_ul li label span {
    font-size: 12px;
    color: #81858a;
    display: block;
    padding-top: 3px;

}

ul.agree_ul li input {
    width: 18px;
    height: 18px;
    margin-top: 0;
    margin-right: 5px;
}

ul.check_box_ul {
    border-radius: 8px;
    width: 580px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

ul.check_box_ul li {
    margin-bottom: 0;
    padding: 10px;
}


ul.check_box_ul li label span {
    font-size: 12px;
    color: #81858a;
    display: block;
    padding-top: 3px;

}

ul.check_box_ul li input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-left: 7px;
}

ul.result_ul {
    display: flex;
    height: 43px;
    padding-top: 7px;
    width: 580px;
}

ul.result_ul li {
    display: flex;
    align-items: center;
    margin-right: 12px;

}

ul.result_ul li input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-top: 5px;
}

ul.result_ul li input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-left: 5px;
}

ul.radio_ul {
    width: 580px;
    display: flex;
}

ul.radio_ul li {
    /*border: 1px solid #E1E4E8;*/
    margin-bottom: 0;

}

ul.radio_ul li input {
    display: none;
}

ul.radio_ul li label {
    border: 1px solid #E1E4E8;
    width: 114px;
    height: 46px;
    border-radius: 8px;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: 400;
    color: #7B7B7B;
}

ul.radio_ul li label.checked {
    border: 1px solid #002060;
    color: #002060;
    font-weight: 500;
}


/* 탭메뉴 */
.tab_menu_wrap {
    background: #fff;
    color: #1c304a;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
}

.tab_menu_wrap .submenu {
    display: flex;
    width: auto;
    padding: 10px 15px 15px;
    transition: all 0.5s;
    border-bottom: 1px solid #fff;
    cursor: pointer;
}

.tab_menu_wrap .focused {
    border-bottom: 1px solid #1c304a;
}

div.desc {
    text-align: left;
    border-top: 1px solid #ccc;
    padding-top: 15px;
}

/* 메인 카드 */
.state_card_wrap {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.state_card_wrap .state_card {
    background: #f8f8f9;
    border: 1px solid #e9ecf0;
    width: 24%;
    border-radius: 8px;
    padding: 20px;
    text-decoration: none;
    color: #1c304a;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
}

.state_card_wrap .state_card .card_icon {
    background: #fff;
    border-radius: 8px;
    width: 70px;
    height: 70px;
    position: relative;
}

.state_card_wrap .state_card .card_icon::before {
    content: "";
    width: 39px;
    height: 39px;
    position: absolute;
    top: 16px;
    left: 15px;
}

.state_card_wrap .state_card:nth-child(1) .card_icon::before {
    background: url(../images/img_sprite.png) 3px -71px no-repeat;
}

.state_card_wrap .state_card:nth-child(2) .card_icon::before {
    background: url(../images/img_sprite.png) -42px -71px no-repeat;
}

.state_card_wrap .state_card:nth-child(3) .card_icon::before {
    background: url(../images/img_sprite.png) -90px -71px no-repeat;
}

.state_card_wrap .state_card:nth-child(4) .card_icon::before {
    background: url(../images/img_sprite.png) -139px -71px no-repeat;
}

.state_card_wrap .state_card:nth-child(5) .card_icon::before {
    background: url(../images/img_sprite.png) -188px -71px no-repeat;
}

.state_card_wrap .state_card .card_icon::after {
    content: "";
    clear: both;
}

.state_card_wrap .state_card .card_info .count {
    font-size: 28px;
    margin-top: 20px;
    font-weight: 700;
    color: #4d5867;
    display: inline-block;
}

.state_card_wrap .state_card .card_info .count .txt_sub {
    font-size: 12px;
    display: inline-block;
}

.state_card_wrap .state_card .card_info .icon_right_arrow {
    background: #fff;
    border-radius: 8px;
    width: 70px;
    height: 70px;
    position: relative;
}

.state_card_wrap .state_card .card_icon::before {
    content: "";
    width: 39px;
    height: 39px;
    position: absolute;
    top: 16px;
    left: 15px;
}

.state_card_wrap .state_card:nth-child(1) .card_icon::before {
    background: url(../images/img_sprite.png) 3px -71px no-repeat;
}

.state_card_wrap .state_card:nth-child(2) .card_icon::before {
    background: url(../images/img_sprite.png) -42px -71px no-repeat;
}

.state_card_wrap .state_card:nth-child(3) .card_icon::before {
    background: url(../images/img_sprite.png) -90px -71px no-repeat;
}

.state_card_wrap .state_card:nth-child(4) .card_icon::before {
    background: url(../images/img_sprite.png) -139px -71px no-repeat;
}

.state_card_wrap .state_card .card_icon::after {
    content: "";
    clear: both;
}

.state_card_wrap .state_card .card_info .count {
    font-size: 28px;
    margin-top: 20px;
    font-weight: 700;
    color: #4d5867;
    display: inline-block;
}

.state_card_wrap .state_card .card_info .count .txt_sub {
    font-size: 12px;
}

/* 공통 */

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    min-width: 120px;
}

.ellipsis_url {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    min-width: 200px;
}

.ellipsis_log {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.split {
    margin: 50px 0;
    border-top: 1px solid #e1e4e8;
}

.wfull {
    width: 100%;
}

.w45 {
    width: 45px !important;
}

.w60 {
    width: 60px !important;
}

.w90 {
    width: 90px !important;
}

.w110 {
    width: 110px !important;
}

.w130 {
    width: 130px !important;
}

.w150 {
    width: 150px !important;
}

.w180 {
    width: 180px !important;
}

.w210 {
    width: 210px !important;
}

.w350 {
    width: 350px !important;
}

.w400 {
    width: 400px !important;
}

.w472 {
    width: 472px !important;
}

.w490 {
    width: 490px !important;
}

.w580 {
    width: 580px !important;
}

.btn_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 30px;
}

.btn {
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    line-height: 20px;
    height: 46px;
}

.right > button {
    margin-left: 10px;
}

.btn_black {
    border: 1px solid #4a535f;
    background: #4a535f;
    color: #fff;
}

.btn_red {
    border: 1px solid #ff5c5c;
    background: #ff5c5c;
    color: #fff;
}

.btn_blue {
    border: 1px solid #002060;
    background: #002060;
    color: #fff;
}

.btn_blue_line {
    border: 1px solid #002060;
    background: #fff;
    color: #002060;
}

.btn_lightblue {
    border: 1px solid rgba(0, 124, 521, 0);
    background: rgba(0, 124, 521, 0.12);
    color: #002060;
}

.btn_gray {
    border: 1px solid #999;
    background: #999;
    color: #fff;
}

.btn_gray_line {
    border: 1px solid #e1e4e8;
    background: #fff;
    color: #4a535f;

}

.btn_sm {
    border-radius: 6px;
    padding: 8px 10px;
    font-size: 12px;
}

.btn_xs {
    border-radius: 6px;
    padding: 1px 15px;
    font-size: 12px;
    max-height: 36px;
}

.mt12 {
    margin-top: 12px;
}

.mt15 {
    margin-top: 15px;
}

.mt30 {
    margin-top: 30px;
}

.mt40 {
    margin-top: 40px;
}

.mr10 {
    margin-right: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb50 {
    margin-bottom: 50px !important;
}

.ml5 {
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.pl10 {
    padding-left: 10px;
}

.none {
    display: none;
}

.txt_sub {
    font-size: 15px;
    font-weight: 300;
    line-height: 16px;
    color: #767575;
}

input::placeholder,
input::-webkit-input-placeholder,
input:-ms-input-placeholder,
textarea::placeholder,
textarea::-webkit-input-placeholder,
textarea:-ms-input-placeholder {
    color: #c1c1c1;
}

.txt_red {
    color: #ff5c5c !important;
}

.txt_orange {
    color: #ffd158 !important;
}

.txt_green {
    color: #15c07d !important;
}

.txt_blue {
    color: #002060 !important;
}

.txt_purple {
    color: #a650cf !important;
}

.badge {
    padding: 2px 5px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
}

.badge_main {
    padding: 2px 5px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
}

.badge_red {
    background: rgba(255, 92, 92, 0.15);
    color: #ff5c5c;
}

.badge_orange {
    background: rgba(255, 209, 88, 0.15);
    color: #ffd158;
}

.badge_green {
    background: rgba(21, 192, 125, 0.15);
    color: #15c07d;
}

.badge_blue {
    background: rgba(0, 124, 521, 0.15);
    color: #007cfb;
}

.badge_purple {
    background: rgba(166, 80, 207, 0.15);
    color: #a650cf;
}

.badge_black {
    background: rgba(28, 48, 74, 0.15);
    color: #1c304a;
}

.noti_txt {
    padding: 12px 0 0 12px;
}

/* 로그인 */
#login {
    vertical-align: middle;
    width: 320px;
    margin: 0 auto;
}

#login h2.title {
    font-size: 32px;
    font-weight: 500;
    color: #1c304a;
    padding: 200px 0 50px;
    text-align: center;
}

#login .name {
    display: none;
}

#login li {
    margin-bottom: 20px;
}
#login li.save_info {
    display: flex;
    justify-content: space-between;
}

#login li.save_info label.reset_pw{
    padding: 3px;
    cursor: pointer;
}
#login label {
    font-size: 15px;
    font-weight: 300;
    color: #4a535f;
}

#login input {
    width: 100%;
    border: 1px solid #e9ecf0;
    border-radius: 8px;
    padding: 12px 15px;
    color: #1c304a;
    font-size: 15px;
    font-weight: 400;
}

#login input:focus {
    outline: 1px solid #002060;
}

#login .warning {
    font-size: 14px;
    text-align: left;
    color: #ff5c5c;
    padding: 8px 0 0 15px;
}

#login .btn_blue {
    margin-top: 20px;
    padding: 15px;
    width: 100%;
}

#login input.check_btn {
    display: none !important;
}

#login input.check_btn + label {
    cursor: pointer;
}

#login input.check_btn + label > span {
    padding-left: 8px;
    font-size: 15px;
    vertical-align: middle;
    color: #9fa19f;
    font-weight: 300;
}

#login input.check_btn + label:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 24px;
    vertical-align: middle;
    background-image: url(../images/check_btn_off.png);
    background-repeat: no-repeat;
    background-position: 50%;
}

#login input.check_btn:checked + label:before {
    content: "";
    background-color: #002060;
    border-color: #002060;
    background-image: url(../images/check_btn.png);
    background-repeat: no-repeat;
    background-position: 50%;
}

#login input.check_btn + label:before,
#login input.check_btn:checked + label:before {
    margin-right: 10px;
}


@media (min-width: 2400px) {
    #login {
        vertical-align: middle;
        width: 400px;
        margin: 0 auto;
    }

    #login h2.title {
        font-size: 40px;
        font-weight: 500;
        color: #1c304a;
        padding: 250px 0 70px;
        text-align: center;
    }

    #login .name {
        display: none;
    }

    #login li {
        margin-bottom: 25px;
    }

    #login label {
        font-size: 18px;
        font-weight: 300;
        color: #4a535f;
    }

    #login input {
        width: 100%;
        border: 1px solid #e9ecf0;
        border-radius: 8px;
        padding: 14px 17px;
        color: #1c304a;
        font-size: 19px;
        font-weight: 400;
    }

    #login input:focus {
        outline: 1px solid #002060;
    }

    #login .warning {
        font-size: 17px;
        text-align: left;
        color: #ff5c5c;
        padding: 8px 0 0 15px;
    }

    #login .btn_blue {
        margin-top: 20px;
        padding: 20px 15px 35px 15px;
        width: 100%;
        font-size: 18px;
    }

    #login input.check_btn {
        display: none !important;
    }

    #login input.check_btn + label {
        cursor: pointer;
    }

    #login input.check_btn + label > span {
        padding-left: 8px;
        font-size: 17px;
        vertical-align: middle;
        color: #9fa19f;
        font-weight: 300;
    }

    #login input.check_btn + label:before {
        content: "";
        display: inline-block;
        width: 26px;
        height: 26px;
        border: 1px solid #d9d9d9;
        background: #fff;
        border-radius: 26px;
        vertical-align: middle;
        background-image: url(../images/check_btn_off.png);
        background-repeat: no-repeat;
        background-position: 50%;
    }

    #login input.check_btn:checked + label:before {
        content: "";
        background-color: #002060;
        border-color: #002060;
        background-image: url(../images/check_btn.png);
        background-repeat: no-repeat;
        background-position: 50%;
    }

    #login input.check_btn + label:before,
    #login input.check_btn:checked + label:before {
        margin-right: 10px;
    }
}

@media (min-width: 2880px) {
    #login {
        vertical-align: middle;
        width: 480px;
        margin: 0 auto;
    }

    #login h2.title {
        font-size: 48px;
        font-weight: 500;
        color: #1c304a;
        padding: 295px 0 90px;
        text-align: center;
    }

    #login .name {
        display: none;
    }

    #login li {
        margin-bottom: 30px;
    }

    #login label {
        font-size: 21px;
        font-weight: 300;
        color: #4a535f;
    }

    #login input {
        width: 100%;
        border: 1px solid #e9ecf0;
        border-radius: 8px;
        padding: 17px 20px;
        color: #1c304a;
        font-size: 22px;
        font-weight: 400;
    }

    #login input:focus {
        outline: 1px solid #002060;
    }

    #login .warning {
        font-size: 20px;
        text-align: left;
        color: #ff5c5c;
        padding: 8px 0 0 15px;
    }

    #login .btn_blue {
        margin-top: 20px;
        padding: 25px 15px 40px 15px;
        width: 100%;
        font-size: 21px;
    }

    #login input.check_btn {
        display: none !important;
    }

    #login input.check_btn + label {
        cursor: pointer;
    }

    #login input.check_btn + label > span {
        padding-left: 8px;
        font-size: 17px;
        vertical-align: middle;
        color: #9fa19f;
        font-weight: 300;
    }

    #login input.check_btn + label:before {
        content: "";
        display: inline-block;
        width: 29px;
        height: 29px;
        border: 1px solid #d9d9d9;
        background: #fff;
        border-radius: 29px;
        vertical-align: middle;
        background-image: url(../images/check_btn_off.png);
        background-repeat: no-repeat;
        background-position: 50%;
    }

    #login input.check_btn:checked + label:before {
        content: "";
        background-color: #002060;
        border-color: #002060;
        background-image: url(../images/check_btn.png);
        background-repeat: no-repeat;
        background-position: 50%;
    }

    #login input.check_btn + label:before,
    #login input.check_btn:checked + label:before {
        margin-right: 10px;
    }
}

@media (min-width: 3840px) {
    #login {
        vertical-align: middle;
        width: 640px;
        margin: 0 auto;
    }

    #login h2.title {
        font-size: 64px;
        font-weight: 500;
        color: #1c304a;
        padding: 393px 0 120px;
        text-align: center;
    }

    #login .name {
        display: none;
    }

    #login li {
        margin-bottom: 40px;
    }

    #login label {
        font-size: 28px;
        font-weight: 300;
        color: #4a535f;
    }

    #login input {
        width: 100%;
        border: 1px solid #e9ecf0;
        border-radius: 8px;
        padding: 23px 27px;
        color: #1c304a;
        font-size: 29px;
        font-weight: 400;
    }

    #login input:focus {
        outline: 1px solid #002060;
    }

    #login .warning {
        font-size: 27px;
        text-align: left;
        color: #ff5c5c;
        padding: 8px 0 0 15px;
    }

    #login .btn_blue {
        margin-top: 20px;
        padding: 35px 15px 50px 15px;
        width: 100%;
        font-size: 28px;
    }

    #login input.check_btn {
        display: none !important;
    }

    #login input.check_btn + label {
        cursor: pointer;
    }

    #login input.check_btn + label > span {
        padding-left: 8px;
        font-size: 17px;
        vertical-align: middle;
        color: #9fa19f;
        font-weight: 300;
    }

    #login input.check_btn + label:before {
        content: "";
        display: inline-block;
        width: 38px;
        height: 38px;
        border: 1px solid #d9d9d9;
        background: #fff;
        border-radius: 38px;
        vertical-align: middle;
        background-image: url(../images/check_btn_off.png);
        background-repeat: no-repeat;
        background-position: 50%;
    }

    #login input.check_btn:checked + label:before {
        content: "";
        background-color: #002060;
        border-color: #002060;
        background-image: url(../images/check_btn.png);
        background-repeat: no-repeat;
        background-position: 50%;
    }

    #login input.check_btn + label:before,
    #login input.check_btn:checked + label:before {
        margin-right: 10px;
    }
}

.search_box .input_search_modal {
    width: 580px !important;
}

.warning {
    font-size: 14px;
    text-align: left;
    color: #ff5c5c;
    padding: 8px 0 0 15px;
}

input.check_btn {
    display: none !important;
}

input.check_btn + label {
    cursor: pointer;
}

input.check_btn + label > span {
    padding-left: 8px;
    font-size: 15px;
    vertical-align: middle;
    color: #9fa19f;
    font-weight: 300;
}

input.check_btn + label:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 24px;
    vertical-align: middle;
    background-image: url(../images/check_btn_off.png);
    background-repeat: no-repeat;
    background-position: 50%;
}

input.check_btn:checked + label:before {
    content: "";
    background-color: #002060;
    border-color: #002060;
    background-image: url(../images/check_btn.png);
    background-repeat: no-repeat;
    background-position: 50%;
}

form input.check_btn + label:before,
form input.check_btn:checked + label:before {
    margin-right: 10px;
}

.attachIcon {
    background: url(../images/copy-solid.svg) no-repeat;
    cursor: pointer;
    background-size: 15px 15px;
}

.api_textarea {
    width: 580px;
    border: 1px solid #e1e4e8;
    height: 46px;
    border-radius: 8px;
    padding: 10px;
    resize: none;
    font-size: 16px;
}

.api_textarea:focus {
    outline: none;
}